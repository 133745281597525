export const environment = {
  production: true,
  reportUrl:'https://ucpaas-report.terragoncdp.com/api/v1',
  apiUrl: 'https://ubetaprime.terragoncdp.com',
  serverUrl: 'https://qaprime.terragoncdp.com/app',
  clientid: 'tmp-client-ubabeta',
  paystackPublicKey: "pk_live_4b18564201f0ac69863f564dc69b43f21947b02e",
  facebookAppId: '295412412010020',
  trackingcode: 'G-5V6B0BMCNF',
  fbCapiInfo: 'https://fbcapiqa.terragoncdp.com/primefbcapiregistration',
  flutterWavePublicKey: "FLWPUBK-449ea1773ad109287abbcaf866e59d38-X",
  audiencePath: 'dev:audience:msisdn:daas:alias',
  envVaribale: 'qa',
};
